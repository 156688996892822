<template>
  <div class="leaderboard-card">
    <div class="leaderboard-date">
      {{returnFormattedDate}}
    </div>  
    <div class="leader-title">
      <h4>Leader</h4>
      <h4 class="gameName">{{leaderboardBreakdown.game.name}}</h4>
      <font-awesome-icon icon="chevron-right" @click="navigateToLeaderboardDetails" style="cursor:pointer"></font-awesome-icon>
    </div>
    <div class="ranking-title">
      <h4>Ranking</h4>
      <h4>Display Name</h4>
    </div>
    <div class="top-ranking">
      <div class="ranking" v-for="index in 5" :key="index"  :style="{background: returnBackgroundColor(index - 1)}">
        <h4 class="ranking-number">{{index }}</h4>
        <div class="rank-details">
          <p class="rank-user">
            <template v-if="leaderboardBreakdown.gameSessions.data[index - 1] != undefined">
              {{leaderboardBreakdown.gameSessions.data[index - 1].user.display_name || `Player ${index}` }}
            </template>
            <template v-else>
              {{ `Player ${index} `}}
            </template>
          </p>
          <p :class="['rank-percentage', { isNegative: parseFloat(leaderboardBreakdown.gameSessions.data[index - 1].roi) < 0.00 }]" v-if="leaderboardBreakdown.gameSessions.data[index - 1] != undefined">
           {{leaderboardBreakdown.gameSessions.data[index - 1].roi || `0.00` }}%
          </p>
          <p v-else>
            {{ `0.00%` }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import moment from 'moment';

export default {
  props:['leaderboardBreakdown'],
  methods:{
    returnBackgroundColor(index){
      switch (index) {
        case 0: return '#FFD700';
        case 1: return '#C0C0C0';
        case 2: return '#D6AF8A';
        default: return '#F0F0F0'
      }
    },
    navigateToLeaderboardDetails(){
      if(this.userRole === 'admin'){
        this.$router.push(`/admin/leaderboard/${this.leaderboardBreakdown.game.id}`)
        return
      }
      this.$router.push(`/leaderboard/${this.leaderboardBreakdown.game.id}`)
    }
  },
  computed:{
    returnFormattedDate(){
      if(this.leaderboardBreakdown.startDate == null)
        return 'All Time'
      return moment(this.leaderboardBreakdown.startDate).format("DD-MMMM-YYYY");
    }
  }
}
</script>

<style lang="scss" scoped>

  .leaderboard-card{
    border-radius: 10px;
    width: 300px;
    margin: 0 20px 20px 0;

    @media screen and (max-width: 600px){
      margin: 0 auto;
      margin-bottom: 20px;
    }




    & > *{
      border-bottom: 1px solid white;
    }
  }

  .leader-title, .ranking-title{
    padding: 20px;
    color: white;
    display: grid;
    grid-template-columns: 80px 1fr auto;
  }

  .leader-title{
    background-color: var(--redC41230);
    // border-radius: 10px 10px 0 0;

    .gameName{
      white-space: nowrap; 
      width: 17ch; 
      overflow: hidden;
      text-overflow: ellipsis; 
    }
  }

  .leaderboard-date{
    background-color: var(--redC41230);
    border-radius: 10px 10px 0 0;
    text-align: end;
    color: white;
    padding: .5rem;
  }

  .ranking-title{
    background-color: var(--gray414241);
  }

  .ranking-number{
    display: grid;
    align-items: center;
    margin-left: 20px;
  }



  .ranking{
    display: grid;
    grid-template-columns: 90px 1fr;
    padding: 10px 20px;
    border-bottom: 1px solid white;    

    &:last-of-type{
      border-bottom:none
    }
  }

  .rank-details{

   

    .rank-percentage{
      color: #009F00;
      font-weight: 700;

      &.isNegative{
        color: var(--redC41230);
      }
    }

    .rank-user{
      font-weight: 700;
    }

  }

</style>