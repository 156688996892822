<template>
  <div class="leaderboard-list">
    <Leaderboard v-for="leaderboard in leaderboardData" :key="leaderboard.game.id" :leaderboardBreakdown="leaderboard"/>
  </div>
</template>

<script>
import Leaderboard from './Leaderboard';

export default {
  props:['leaderboardData'],
  components:{
    Leaderboard
  }
}
</script>

<style lang="scss" scoped>
  .leaderboard-list{
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;

    @media screen and (max-width: 450px){
      margin-bottom: 1rem;
    }
  }
</style>