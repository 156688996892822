<template>
  <ContentWrapper>
    <template v-slot:title>Leaderboard</template>
    <Container>
      <div class="sort-container">
        <h3>Time Interval</h3>
        <div class="sort-options">

          <ButtonWithSelect 
            :items="fixedWeeklyList" 
            filterType="week" 
            defaultOption="Weekly"
            @filterChange="updateFilter($event,'weekFilter')"
            :disabled="weekIsDisabled"
            ref="weekComponent"
            class="options"
          />


          <ButtonWithSelect 
            :items="fixedMonthList" 
            filterType="month" 
            defaultOption="Monthly"
            @filterChange="updateFilter($event,'monthFilter')"
            :disabled="monthIsDisabled"
            class="options"
          />

          <ButtonWithSelect 
            :items="fixedYearList" 
            filterType="year" 
            defaultOption="Yearly"
            @filterChange="updateFilter($event,'yearFilter')"
            :disabled="yearIsDisabled"
            class="options"
          />

          <SubmitButton @onButtonClick="allTimeToggle" :disabled="allTimeIsDisabled"  class="options">All Time</SubmitButton>
        </div>
      </div>
      <LeaderboardList :leaderboardData="leaderboardList"/>
    </Container>
  </ContentWrapper>
</template>

<script>
import Container from '@/components/Container';
import SubmitButton from '@/components/SubmitButton';
import LeaderboardList from './components/LeaderboardList';
import ButtonWithSelect from "@/components/ButtonWithSelect";
import moment from 'moment'

export default {
  components:{
    Container,
    SubmitButton,
    LeaderboardList,
    ButtonWithSelect
  },
  data(){
    return{
      weekFilter: '',
      monthFilter: '',
      yearFilter: '',
      weekIsDisabled : false,
      monthIsDisabled : false,
      yearIsDisabled : false,
      allTimeIsDisabled: true,
      leaderboardList: [],
      fixedWeeklyList:[],
      fixedMonthList: [
        {
          id: 1,
          name : 'January'
        },
        {
          id: 2,
          name : 'February'
        },
        {
          id: 3,
          name : 'March'
        },
        {
          id: 4,
          name : 'April'
        },
        {
          id: 5,
          name : 'May'
        },
        {
          id: 6,
          name : 'Jun'
        },
        {
          id: 7,
          name : 'July'
        },
        {
          id: 8,
          name : 'August'
        },
        {
          id: 9,
          name : 'September'
        },
        {
          id: 10,
          name : 'October'
        },
        {
          id: 11,
          name : 'November'
        },
        {
          id: 12,
          name : 'December'
        },
      ],
      fixedYearList: [
        {
          id: 2021,
          name : "2021"
        }
      ]
    }
  },
  methods:{
    updateFilter(data,attr){
      this[attr] = data;
      
      this.getUpdatedLeaderboard(attr,data);
    },
    getUpdatedLeaderboard(changeProperty){
      let params = null;

      if(this.yearFilter == 'Yearly' || this.monthFilter == 'Monthly' || this.yearFilter == 'Weekly'){
        this.$store.dispatch('callLeaderboardList',{});
        return;
      };

      if(changeProperty == 'monthFilter'){
        params = {
          month: this.monthFilter
        };
        [this.allTimeIsDisabled,this.yearIsDisabled,this.weekIsDisabled] = [true,true,true];
        this.monthIsDisabled = false;
      }else if(changeProperty == 'yearFilter'){
        params = {
          year: this.yearFilter
        };
        [this.allTimeIsDisabled,this.monthIsDisabled,this.weekIsDisabled] = [true,true,true];
        this.yearIsDisabled = false;
      }else if(changeProperty == 'weekFilter'){
        params = {
          week: this.weekFilter
        };
        [this.allTimeIsDisabled,this.yearIsDisabled,this.monthIsDisabled] = [true,true,true];
        this.weekIsDisabled = false;
      }

      this.$store.dispatch('callLeaderboardList',{params});
    },
    allTimeToggle(){
      this.allTimeIsDisabled = !this.allTimeIsDisabled;
      this.weekIsDisabled = true;
      this.yearIsDisabled = true;
      this.monthIsDisabled = true;
      this.$store.dispatch('callLeaderboardList',{});
    },
    dateOfSpecificWeek(week){
      return moment().isoWeek(week).startOf('isoWeek').format('DD MMM YYYY')
    }
  },
  computed:{
    leaderBoardList(){
      return this.$store.getters.getLeaderboardList;
    },
    weeksInYear(){
      return moment().weeksInYear();
    }
  },
  watch:{
    leaderBoardList(newValue){
      this.leaderboardList = newValue
    }
  },
  created(){

    const params = {
      week: moment().isoWeek()
    };

    this.$store.dispatch('callLeaderboardList',{params});

    this.leaderboardList = this.leaderBoardList;
    this.fixedWeeklyList = [...Array(this.weeksInYear).keys()].map((index) =>  ({
      id: index + 1,
      name: `Week ${index + 1}: ${this.dateOfSpecificWeek(index + 1)}`,
    }));

  },
  mounted(){
    this.$refs.weekComponent.selected = moment().isoWeek(); 
  
  }
}
</script>

<style lang="scss" scoped> 

.content-container{
  min-height: calc(100vh - 60px - 58px);

  @media screen and (max-width:450px){
    height: calc(100vh - 60px - 58px);
  }
}

.sort-container{
  h3{
    @media screen and (max-width: 600px){
      display: none;
    }
  }
}


.sort-options{
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
  .space{
    margin-right: 30px;
  }

  .options{
    margin-bottom: 20px;

    @media screen and (max-width:900px){
      margin-right: 15px;
    }

    @media screen and (max-width:600px){
      margin-right: 0;
    }
  }
}

@include respond(phone){
  .sort-options{
    margin-left:  0;
    color: white;
    width: 100%;
    margin-top: 0;
    // flex-wrap: nowrap;

    .options{
      flex: 1 1 100px;
      border-radius: 0;
      margin-bottom: 20px;
    }

    select{
      width: unset;
    }

    .custom-select{
      margin-right: 0;
      border-radius: 0;
      max-width: unset;
    }

    .nav-btn{
      width: 25%;
      padding: 1rem 0;
      border-right: 1px solid white;
      text-align: start;
    }
    .space{
      margin: 0 1rem;
    }
  }
  .content-container{
    padding: 0;
  }
}

</style>